<template>
  <nav class="navbar">
    <section class="navbar-section">
      <router-link
        :to="{name: 'main'}"
        class="navbar__logo"
        :class="logoColor"
      />
    </section>
    <section class="navbar-section">
      <router-link :to="{name: 'register'}" class="navbar__btn" v-if="showContact">
        Contact
      </router-link>
    </section>
  </nav>
</template>

<script>
export default {
  props: {
    logoColor: {
      type: String,
      default: 'white'
    },
    showContact: {
      type: Boolean,
      default: true
    },
  },
  name: "Navbar"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/components/Navbar"
</style>
