import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import store from '@/store/index'
import VTooltip from 'v-tooltip'
import router from '@/router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import api from '@/services/api/index'
import Vuelidate from 'vuelidate'


Vue.config.productionTip = false
Vue.use(VTooltip)
Vue.use(VueAxios, axios)
Vue.use(api)
Vue.use(Vuelidate)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
