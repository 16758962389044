import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "@/views/MainPage/MainPage";
import RegisterPage from "@/views/RegisterPage/RegisterPage";

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'main',
      component: MainPage
    },
    {
      path: '/sign-up',
      name: 'register',
      component: RegisterPage
    }
  ]
})
