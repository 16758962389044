<template>
  <div class="wrapper footer-wrapper">
    <div class="container">
      <div class="footer">
        <section class="footer-section">
          <router-link
            :to="{name: 'main'}"
            class="footer__logo white"
          />
        </section>
        <section class="footer-section">
          <router-link :to="{name: 'register'}" class="footer__btn">
            Contact
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/components/Footer.sass"
</style>
