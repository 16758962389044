import {apiRoot} from "@/services/api/instances";
import user from "@/services/api/user"

const repositories = {
  user: user(apiRoot)
}

export default {
  // method is used by Vue.use
  install: Vue => {
    Vue.prototype.$api = repositories
  },

  // for use outside Vue
  ...repositories,
}
