<template>
  <div class="navbar">
    <div class="navbar-header">
      <div class="navbar-header__title">
        Registration Form
      </div>
      <div class="navbar-header__text">
        Make better decisions
      </div>
    </div>
    <div class="navbar-main">
      <div
        class="navbar-main__item"
        :class="{active: currentStage.id === stage.id}"
        v-for="stage in stages"
        :key="stage.id"
      >
        {{ stage.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stages: {
      type: Array,
      default: [],
    },
    currentStage: {
      type: Object,
      default: null
    }
  },
name: "RegisterPageNavbar"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/RegisterPage/RegisterPageNavbar.sass"
</style>
