<template>
  <div class="wrapper page-wrapper" v-if="isPageLoaded">
    <div class="container">
      <Navbar
        logo-color="blue"
        :show-contact="false"
      />
      <div class="page">
        <template v-if="!showThanks">
          <RegisterPageNavbar
            :stages="stages"
            :current-stage="currentStage"
          />
          <RegisterPageForm
            :current-stage="currentStage"
            @prevStage="changeStage"
            @nextStage="changeStage"
            @updateUserData="updateUserData"
          />
        </template>
        <template v-else>
          <RegisterPageThanks/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import RegisterPageNavbar from "@/views/RegisterPage/RegisterPageNavbar";
import RegisterPageForm from "@/views/RegisterPage/RegisterPageForm";
import RegisterPageThanks from "@/views/RegisterPage/RegisterPageThanks";

export default {
  name: "RegisterPage.vue",
  components: {RegisterPageThanks, RegisterPageForm, RegisterPageNavbar, Navbar},
  data() {
    const firstStage = {
      id: 1,
      text: 'Getting to Know you'
    }
    return {
      isPageLoaded: false,
      maxStage: 3,
      stages: [
        firstStage,
        {
          id: 2,
          text: 'What do you do'
        },
        {
          id: 3,
          text: 'What are you looking for'
        },
      ],
      showThanks: false,
      currentStage: firstStage,
      userData: {},
    }
  },
  async created() {
    if (this.$route.query.token) {
      try {
        await this.$api.user.verify(this.$route.query.token)
        this.showThanks = true
      } catch (e) {
        console.log('FOR UNEXPECTED ERRORS', e)
      }
    }
    this.isPageLoaded = true
  },
  methods: {
    changeStage(id) {
      if (id > this.maxStage) {
        this.endRegister()
      } else {
        this.currentStage = this.stages.find(stage => stage.id === id)
      }
    },
    async endRegister() {
      this.$api.user.create(this.userData)
      this.showThanks = true
    },
    updateUserData(data) {
      this.userData = {...data}
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/RegisterPage/RegisterPage.sass"
</style>
