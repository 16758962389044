<template>
  <div class="wrapper page-wrapper">
    <div class="container">
      <div class="page">
        <header class="page-header">
          <div class="page-header-features">
            <div
              class="page-header-features-item"
              v-for="(item, index) in features"
              :key="index"
            >
              <div class="page-header-features-item__title">{{ item.title }}</div>
              <div class="page-header-features-item__divider"></div>
              <p class="page-header-features-item__text">{{ item.text }}</p>
            </div>
          </div>
        </header>
        <main class="page-main">
          <section class="page-main-section page-main-section-info">
            <div class="page-main__title">
              Get the info not the cost
            </div>
            <div class="page-main__text">
              Who said conducting a research should cost you and arm and a leg. You don’t need to pay the hefty charges
              of market research agencies anymore as we use a professional team of research to deliver the best result
              with minimum cost .
            </div>
            <div class="page-main__text blue">
              Don’t stay in the dark ….<br> Get the info you need now !
            </div>
            <router-link :to="{name: 'register'}" class="page-main-btn-section">
              <div class="page-main-btn-section__btn">
                Get early access now
              </div>
              <div class="page-main-btn-section__arrow-btn">
                Sign up now
              </div>
            </router-link>
          </section>
          <section class="page-main-section page-main-section-image">
            <div class="page-main__image"></div>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPageInfo",
  data() {
    return {
      features: [
        {
          title: 'Market \nresearch',
          text: 'What is the size of the dairy market in Saudi and what are the different segments',
        },
        {
          title: 'Prove a \npoint',
          text: 'Need evidence that functional commination delivers better impact',
        },
        {
          title: 'Compare – \nanalyse',
          text: 'Female Employment at Public vs privet sector in Saudi',
        },
        {
          title: 'Collect \ndata',
          text: 'List of organic suppliers in the GCC',
        },
      ]
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPageInfo.sass"
</style>
