<template>
  <div class="wrapper page-wrapper">
    <div class="container">
      <div class="page">
        <div class="page-section">
          <div class="page__image"></div>
        </div>
        <div class="page-section">
          <h2 class="page__title">
            How it works
          </h2>
          <p class="page__text">
            You tell us what are you working on and we will gather the right research team for you provide you with cost estimate for your approval before starting the work . along the way you will have a the chance to add more or reduce the scope according to the finding and your budget , its all up to you now questions asked!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "MainPageHowItWorks"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPageHowItWorks.sass"
</style>
