<template>
  <div class="wrapper header-wrapper">
    <div class="container">
      <Navbar/>
      <div class="header">
        <div class="header-section">
          <h1 class="header__title">
            Inphormable
          </h1>
          <p class="header__text">
            Get your research done for a fraction of the cost
          </p>
          <router-link
            :to="{name: 'register'}"
            class="header-btn-section pc"
          >
            <div class="header-btn-section__btn">
              Get early access now
            </div>
            <div  class="header-btn-section__arrow-btn">
              Sign up now
            </div>
          </router-link>
        </div>
        <div class="header-section">
          <div class="header__image"></div>
        </div>
        <router-link
          :to="{name: 'register'}"
          class="header-btn-section mobile"
        >
          <div class="header-btn-section__btn">
            Get early access now
          </div>
          <div  class="header-btn-section__arrow-btn">
            Sign up now
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "MainPageHeader",
  components: {Navbar}
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPageHeader.sass"
</style>
