<template>
  <div class="page">
    <MainPageHeader/>
    <MainPageInfo/>
    <MainPageDecision/>
    <MainPageHowItWorks/>
    <MainPageExamples/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import MainPageHeader from "@/views/MainPage/MainPageHeader";
import MainPageInfo from "@/views/MainPage/MainPageInfo";
import MainPageDecision from "@/views/MainPage/MainPageDecision";
import MainPageHowItWorks from "@/views/MainPage/MainPageHowItWorks";
import MainPageExamples from "@/views/MainPage/MainPageExamples";
import Footer from "@/components/Footer";

export default {
  name: "MainPage",
  components: {Footer, MainPageExamples, MainPageHowItWorks, MainPageDecision, MainPageInfo, MainPageHeader, Navbar}
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPage.sass"
</style>
