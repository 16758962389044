<template>
  <div class="wrapper page-wrapper">
    <div class="container">
      <div class="page">
        <h2 class="page__title">
          Examples of our research :
        </h2>
        <div class="page-examples">
          <div
            class="page-examples-item"
            v-for="item in examples"
            :key="item.title"
          >
            <div class="page-examples-item__title">
              {{ item.title }}
            </div>
            <div class="page-examples-item-content">
              <div class="page-examples-item-content-inner" v-for="(text, index) in item.items" :key="index">
                <div class="page-examples-item-content-inner__circle"></div>
                <div class="page-examples-item-content-inner__divider" v-if="item.items.length !== index + 1"></div>
                <div class="page-examples-item-content-inner__text">{{ text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPageExamples",
  data() {
    return {
      examples: [
        {
          title: 'Data :',
          items: [
            'What is the size and the segments of the ice cream market in Saudi Arabia',
            'What is the spend on restaurant during Eid break in Jeddah vs Riyadh ',
            'What percentage of people use their smart phone to stream movies and shows ',
          ]
        },
        {
          title: 'List :',
          items: [
            'What are the top IT collages in 2020',
            'What is the average price for a house in Riyadh ',
            '10 cheapest summer destinations in 2021',
          ]
        },
        {
          title: 'Insights:',
          items: [
            'What are the barriers of ecommerce in the GCC ',
            'What is the outlook of nonoil sector in Saudi Arabia vs Kuwait ',
            'What is the impact of VAT on the consumers process in Saudi Arabia ',
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPageExamples.sass"
</style>
