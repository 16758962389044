<template>
  <div class="wrapper page-wrapper">
    <div class="container">
      <div class="page">
        <h2 class="page__title">
          Make better decisions
        </h2>
        <p class="page__text">
          {{ `Don’t stay in the dark …\n
          Get the info you need now !` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPageDecision"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/MainPage/MainPageDecision.sass"
</style>
