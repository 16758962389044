<template>
  <div class="thanks">
    <img
      src="~@/assets/images/thanks_checkmark.svg"
      class="thanks__icon"
      alt="thanks icon"
    >
    <div class="thanks__title">
      Thanks  for registering!
    </div>
    <div class="thanks__text">
      Lorem ipsum dolor sit amet
    </div>
    <router-link :to="{name: 'main'}" class="thanks__btn">
      Back to home
    </router-link>
  </div>
</template>

<script>
export default {
  name: "RegisterPageThanks"
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/RegisterPage/RegisterPageThanks.sass"
</style>
