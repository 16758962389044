<template>
  <div class="form">
    <header class="form-header">
      {{ currentStage.text }}
    </header>
    <main class="form-main">
      <template v-if="currentStage.id === 1">
        <div class="form-group">
          <label for="first-name" class="form-group__label">First Name</label>
          <input v-model="selected.first_name" id="first-name" type="text"/>
        </div>
        <div class="form-group">
          <label for="phone-number" class="form-group__label">Mobile number </label>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <input :value="currentCode + phoneNumber" @input="e => this.phoneNumber = e.target.value.replace(currentCode, '')" id="phone-number" type="text"/>
            <template #popover>
              <div class="base-popover">
                <input placeholder="Search" v-model="searchCode" class="base-popover__item form-group__input">
                <div
                  v-close-popover class="base-popover__item"
                  v-for="(code, index) in filteredCodes"
                  :key="index"
                  @click="currentCode = code.dial_code"
                >
                  {{ code.dial_code }} {{ code.name }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="form-group">
          <label for="last-name" class="form-group__label">Last Name </label>
          <input v-model="selected.last_name" id="last-name" type="text"/>
        </div>
        <div class="form-group">
          <div class="form-group__label">Language preference </div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ selected.language_preference }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover class="base-popover__item"
                  v-for="lang in languageChoices"
                  :key="lang"
                  @click="selected.language_preference = lang"
                >
                  {{ lang }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="form-group">
          <label for="email" class="form-group__label">Email address </label>
          <input v-model="selected.email" id="email" type="email"/>
        </div>
        <div class="form-group">
          <div class="form-group__label">Your age</div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ selected.age }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover class="base-popover__item"
                  v-for="(age, index) in ageChoices"
                  :key="index"
                  @click="selected.age = age"
                >
                  {{ age }}
                </div>
              </div>
            </template>
          </v-popover>
<!--          <input v-model="selected.age" id="age" min="0" type="number"/>-->
        </div>
      </template>
      <template v-else-if="currentStage.id === 2">
        <div class="form-group">
          <div class="form-group__label">Position</div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ currentOptLabel('position') }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover
                  class="base-popover__item"
                  v-for="pos in positionChoices"
                  :key="pos.value"
                  @click="selected.position = pos"
                >
                  {{ pos.label }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="form-group">
          <div class="form-group__label">Role</div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ currentOptLabel('role') }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover class="base-popover__item"
                  v-for="role in roleChoices"
                  :key="role.value"
                  @click="selected.role = role"
                >
                  {{ role.label }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="form-group">
          <div class="form-group__label">Industry</div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ currentOptLabel('industry') }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover class="base-popover__item"
                  v-for="industry in industryChoices"
                  :key="industry.value"
                  @click="selected.industry = industry"
                >
                  {{ industry.label }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
      </template>
      <template v-else-if="currentStage.id === 3">
        <div class="form-group">
          <div class="tooltip-target form-group__label">
            Data for business or personal
          </div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ currentOptLabel('data_for') }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover
                  class="base-popover__item"
                  v-for="pos in dataForChoices"
                  :key="pos.value"
                  @click="selected.data_for = pos"
                >
                  {{ pos.label }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="form-group">
          <div class="tooltip-target form-group__label">
            What would interest you ?
          </div>
          <v-popover
            offset="4"
            display="block"
            placement="bottom"
          >
            <div class="form-group__input arrow">
              {{ currentOptLabel('interest') }}
            </div>
            <template #popover>
              <div class="base-popover">
                <div
                  v-close-popover
                  class="base-popover__item"
                  v-for="pos in whatWouldInterestChoices"
                  :key="pos.value"
                  @click="selected.interest = pos"
                >
                  {{ pos.label }}
                </div>
              </div>
            </template>
          </v-popover>
        </div>
      </template>
    </main>
    <footer class="form-footer">
      <div
        class="form-footer__btn white"
        v-if="isPrevStageAvailable"
        @click="prevStage"
      >
        Back
      </div>
      <div
        class="form-footer__btn blue"
        :class="{disabled: !isNextStageAvailable}"
        @click="nextStage"
      >
        Next
      </div>
    </footer>
  </div>
</template>

<script>
import {required, email, minValue} from 'vuelidate/lib/validators'
import constants from "@/constants";

export default {
  props: {
    currentStage: {
      type: Object,
      default: null
    },
  },
  name: "RegisterPageForm",
  validations() {
    return {
      selected: {
        position: {required},
        role: {required},
        industry: {required},
        data_for: {required},
        interest: {required},
        first_name: {required},
        phone: {required},
        last_name: {required},
        language_preference: {required},
        email: {required, email},
        age: {required, minValue: minValue(0)},
      },
    }
  },
  watch: {
    selected: {
      handler(newVal) {
        const newData = {...newVal}
        this.choiceFields.forEach(fieldName => {
          if (newData[fieldName]) {
            newData[fieldName] = newData[fieldName].value
          }
        })
        this.$emit('updateUserData', newData)
      },
      deep: true,
    },
    currentCode(newVal) {
      this.selected.phone = `${newVal}${this.phoneNumber}`
    },
    phoneNumber(newVal) {
      console.log('here')
      this.selected.phone = newVal
    },
  },
  data() {
    return {
      currentCode: '',
      phoneNumber: '',
      searchCode: '',
      codeChoices: constants.CODE_CHOICES,
      languageChoices: ['EN', 'AR'],
      selected: {
        position: null,
        role: null,
        industry: null,
        data_for: null,
        interest: null,
        first_name: null,
        phone: null,
        last_name: null,
        language_preference: null,
        email: null,
        age: null,
      },
      choiceFields: ['position', 'role', 'industry', 'data_for', 'interest'],
      positionChoices: [
        {
          label: "Business Owner",
          value: 0,
        },
        {
          label: "Employee",
          value: 1,
        },
        {
          label: "Manager",
          value: 2,
        },
        {
          label: "Director",
          value: 3,
        },
        {
          label: "VP",
          value: 4,
        },
      ],
      roleChoices: [
        {
          label: 'Entrepreneur',
          value: 0
        },
        {
          label: 'Consultant',
          value: 1
        },
        {
          label: 'Strategy / Analyst',
          value: 2
        },
        {
          label: 'Sales / Marketing',
          value: 3
        },
        {
          label: 'Investment Professional',
          value: 4
        },
        {
          label: 'Operation',
          value: 5
        },
        {
          label: 'IT',
          value: 6
        },
        {
          label: 'Product Manager',
          value: 7
        },
        {
          label: 'Accounting Management',
          value: 8
        },
        {
          label: 'Others',
          value: 9
        }
      ],
      industryChoices: [
        {
          label: 'Agriculture',
          value: 0,
        },
        {
          label: 'Chemical',
          value: 1,
        },
        {
          label: 'IT & Computer',
          value: 2,
        },
        {
          label: 'Construction',
          value: 3,
        },
        {
          label: 'Energy',
          value: 4,
        },
        {
          label: 'Financial Insurance',
          value: 5,
        },
        {
          label: 'FMGG',
          value: 6,
        },
        {
          label: 'Other',
          value: 7,
        },
        {
          label: 'Entertainment',
          value: 8,
        },
        {
          label: 'Food',
          value: 9,
        },
        {
          label: 'Health',
          value: 10,
        },
        {
          label: 'Hospitality',
          value: 11,
        },
        {
          label: 'Manufacturing',
          value: 12,
        },
        {
          label: 'Media',
          value: 13,
        },
        {
          label: 'Telecommunications',
          value: 14,
        },
      ],
      dataForChoices: [
        {
          label: "For business",
          value: 0,
        },
        {
          label: "Personal",
          value: 1,
        },
      ],
      whatWouldInterestChoices: [
        {
          label: "Market research",
          value: 0,
        },
        {
          label: "Prove a point",
          value: 1,
        },
        {
          label: "Compare/analyse",
          value: 2,
        },
        {
          label: "Collect data",
          value: 3,
        },
      ],
    }
  },
  methods: {
    prevStage() {
      if (this.isPrevStageAvailable) {
        this.$emit('prevStage', this.currentStage.id - 1)
      }
    },
    nextStage() {
      if (this.isNextStageAvailable) {
        this.$emit('nextStage', this.currentStage.id + 1)
      }
    },
    currentOptLabel(param) {
      return this.selected[param]?.label
    },
    isFieldsInvalid(fieldsArray) {
      return fieldsArray.some(field => this.$v.selected[field].$invalid)
    }
  },
  computed: {
    isPrevStageAvailable() {
      return this.currentStage.id > 1
    },
    isNextStageAvailable() {
      return !this.isFieldsInvalid({
        1: ['first_name', 'phone', 'last_name', 'language_preference', 'email', 'age'],
        2: ['position', 'role', 'industry'],
        3: ['data_for', 'interest'],
      }[this.currentStage.id])
    },
    filteredCodes() {
      return this.searchCode ? this.codeChoices.filter(code => {
        return `${code.dial_code} ${code.name}`.includes(this.searchCode)
      }) : this.codeChoices
    },
    ageChoices() {
      return Array.from(Array(150).keys()).map(item => item + 18)
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/views/RegisterPage/RegisterPageForm.sass"
</style>
