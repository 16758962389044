import axios from "axios";
import config from '@/config';
import Cookies from 'js-cookie'

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

const apiRoot = axios.create({
  baseURL: config.API_ROOT + 'api/v1', headers: {
    "X-CSRFToken": Cookies.get('csrftoken')
  }
})

export {
  apiRoot,
}
